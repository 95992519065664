import { memo, useMemo } from 'react';
import {
  CasePhaseModel,
  EventCaseModel,
  EventDescriptionCode,
} from '@kk/api/modules/case';
import Breadcrumbs, {
  BreadcrumbItem,
} from '@kk/ui/components/Breadcrumbs/Breadcrumbs';
import StatusTag from '@kk/ui/components/StatusTag/StatusTag';
import { useLoaderData, useMatches, useParams } from 'react-router-dom';
import { caseApi } from '@/api';
import useMapPhaseStatus from '@/hooks/useMapPhaseStatus';
import routes from '@/routes';
import { ExtendedRouteObject } from '@/routes/types';
import { ChangeablePhaseState } from '../ChangeablePhaseState/ChangeablePhaseState';

export interface BreadcrumbMatchesProps {}

/** The purpose of this component is to generate breadcrumbs by examining the current route and extracting breadcrumb definitions from the route configuration. */
export function BreadcrumbMatches() {
  const data = useLoaderData();
  const params = useParams();
  const matches = useMatches();
  const match = matches[1];

  const crumbs = useMemo<BreadcrumbItem[]>(() => {
    const paramKeys = Object.keys(params);
    return (
      routes // TODO: refactor this to handle nested routes
        .map((route: ExtendedRouteObject<unknown, unknown>) => {
          // fill out all defined routes with params
          let url: string = route.path ?? '';
          const pathPlaceholders = route.path?.match(/:[a-zA-Z]+/g);
          // iterate over all params and replace placeholders with values
          for (const key in paramKeys) {
            const paramKey = paramKeys[key] ?? '';
            const paramValue = params[paramKey] ?? '';
            if (pathPlaceholders?.includes(`:${paramKey}`)) {
              url = url.replace(`:${paramKey}`, encodeURIComponent(paramValue));
            }
          }
          return { url, route };
        })
        // find path matches that start with the current route
        .filter(({ url }) => {
          return match?.pathname.startsWith(url) && url !== '/';
        })
        // flatten to allow for multiple breadcrumbs per route
        .flatMap(({ route, url }) => {
          if (typeof route.handle !== 'function') return null;
          const request = new Request(url);

          // call custom handle function to get breadcrumb definition
          const breadcrumbDefinition = route.handle({
            data,
            params,
            request,
          }).breadcrumb;

          if (!breadcrumbDefinition) return null;

          // if breadcrumb definition is an array, map over it and add url to each item but allowing for custom overrides
          if (Array.isArray(breadcrumbDefinition)) {
            return breadcrumbDefinition.map((breadcrumb: BreadcrumbItem) => ({
              to: url,
              ...breadcrumb,
            })) satisfies BreadcrumbItem[];
          }

          // if breadcrumb definition is an object, add url to it but allowing for custom overrides
          return {
            to: url,
            ...breadcrumbDefinition,
          } satisfies BreadcrumbItem;
        })
        .filter(Boolean)
    );
  }, [match, params, data]);

  return <Breadcrumbs items={crumbs} />;
}

function PhaseStatusTag({
  phase,
  eventCase,
}: {
  phase: CasePhaseModel;
  eventCase: EventCaseModel;
}) {
  const params = useParams();
  const mapPhaseToStatus = useMapPhaseStatus();
  if (
    eventCase.eventDescription === EventDescriptionCode.NewLoan &&
    eventCase.casePhaseCode === caseApi.CasePhaseCode.Calculation &&
    eventCase.isActive
  ) {
    return (
      <ChangeablePhaseState
        className="ml-2"
        eventCase={eventCase}
        activePhase={phase}
        companyId={params.companyId}
        contractId={params.contractId}
      />
    );
  }
  return <StatusTag {...mapPhaseToStatus(phase, eventCase)} className="ml-2" />;
}

BreadcrumbMatches.PhaseStatusTag = memo(PhaseStatusTag);

export default BreadcrumbMatches;
