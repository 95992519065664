import { z } from 'zod';

export const flagSchema = z.object({
  /**
   * Enables notifications.
   * @feature Feature 27259
   * @see {@link https://dev.azure.com/kommunekredit/KK%20Laaneportal/\_workitems/edit/27259}
   */
  enableNotifications: z.boolean(),
  enableNewLoanCreation: z.boolean(),
});

export type Flags = z.infer<typeof flagSchema>;

const config: Flags = {
  enableNotifications: false,
  enableNewLoanCreation: true,
};

const featureFlags = flagSchema.parse(config);

export default featureFlags;
